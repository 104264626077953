import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import './styles.scss';

const IncludeButtonTemplate = withStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            height: '60px'
        },
        [theme.breakpoints.down('xs')]: {
          height: '40px'
        }
    },
    label: {
        padding: '30px 35px 30px 30px',
        fontFamily: '"Kanit Medium", san-serif',
        fontSize: '16px',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            padding: '0px 10px 0px 10px'
        }
    },
    endIcon: {
        marginLeft: '35px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '16.6px'
        }
    }
}))(Button);

const IncludeButton = (props) => {
    let {
        text,
        textColor,
        btnBackgroundColor = 'transparent',
        iconColor,
        icon,
        onClick,
        type,
        classType = 'content-type',
    } = props;
    const safeText = String(text);
    const setIcon = icon || 'include_custom_arrow'
    return <IncludeButtonTemplate
        className={`inc-button ${classType}`}
        type={type}
        onClick={onClick}
        style={ {color:textColor, backgroundColor:btnBackgroundColor}}
        endIcon={<Icon style={{ backgroundColor:iconColor }} className={setIcon}/>}
    >
        {safeText.toUpperCase()}
    </IncludeButtonTemplate>;
}

export default IncludeButton;
