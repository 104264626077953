import React from 'react';
import './styles.scss';
import HexagonIcon from './HexagonIcon';
import IncludeButton from '../Button';
import { contentButtonIconColor, contentTextColor } from '../../constants';
import { useHistory } from 'react-router-dom';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ServiceCard = (props) => {

    const {
        icon,
        header,
        text,
        bg,
        color,
        border = true,
        linkTo,
        isSingleType = false,
        btnIconColor = contentButtonIconColor
    } = props;

    const history = useHistory()

    return (
        <div className={`${isSingleType ? 'service-card-single' :'service-card'}`}>
            <div className="card-content">
                <div className="header-row" >
                    <HexagonIcon icon={icon} bg={bg} color={color} />
                    <div className="card-heading"><Text field={header} /></div>
                </div>
                <div className={`card-text ${border ? '' : 'no-border'} `}>
                    <RichText field={text} />
                </div>
                {linkTo && linkTo.value && linkTo.value.href ? <div className="learn-more-btn">
                    <IncludeButton
                        color={contentTextColor}
                        height="56px"
                        iconColor={btnIconColor}
                        onClick={ () => {
                            history.push(linkTo.value.href)
                        }}
                        text="Learn more" />
                </div> : ''}
            </div>
        </div>
    )
}

export default ServiceCard;
